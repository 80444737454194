export default [
  {
    path: '/lotto12-balance-report',
    name: 'lotto12-balance-report',
    component: () => import('@/views/lotto12BalanceReport/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.lotto12BalanceReport',
      resource: 'balance-report-lotto12',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listLotto12BalanceReport',
          active: true,
        },
      ],
    },
  },
]
