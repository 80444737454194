import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

Vue.filter('formatDate', (value, format = 'DD-MM-YYYY HH:mm:ss') => {
  if (!value) {
    return ''
  }

  return moment(value).format(format)
})

Vue.filter('formatTime', (value, locale) => {
  if (!value) {
    return ''
  }

  return moment(value, 'HH:mm')
    .locale(locale)
    .format('h:mm a')
})
